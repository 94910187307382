import { Power1, Power2 } from 'gsap';

export default function animateIncubatorImage(
  tl,
  svgLine,
  svgCircle,
  title,
  desc,
  image,
  container
) {
  const o = 0.7;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo(desc, o, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, o)
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      container,
      1.3,
      {
        x: -120,
        y: 20,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1
    )
    .staggerFrom(
      image,
      1.3,
      {
        x: '70px',
        y: '-70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      1
    );
}

export function animateApplyDescription(tl, svgLine, svgCircle, title, desc) {
  const o = 0.7;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo(desc, o, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, o)
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut })
    .staggerFromTo(
      desc,
      0.5,
      {
        opacity: 0,
        y: 50,
        ease: Power1.easeOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power1.easeOut,
      },
      0
    );
}
