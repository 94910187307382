import React, { PureComponent } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { TimelineLite } from 'gsap';
import { Helmet } from 'react-helmet';
import Observer from 'react-intersection-observer';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/_apply-now.scss';
import ReadMore from '../../static/assets/black_arrow.svg';
import LightBluekey from '../../static/assets/light_blue_key.svg';
import LightBluekeySecond from '../../static/assets/light_blue_key_second.svg';
import * as globalAnimation from '../animations/globalAnimation';
import * as Probono from '../animations/Probono';
import { animateApplyDescription } from '../animations/applyNow';

export default class ApplyNow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      id: null,
    };
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.AnimateEaseInOut(this.tl, '.iframe-wrapper');
    globalAnimation.animateImageRight(
      this.tl,
      '.svg-line__line_lightblue',
      '.svg_circle_lightblue',
      '.apply-image',
      '.blue_bg',
      '#title',
      '#description',
      '#lists'
    );
  }

  handleShowModal = () => {
    if (window.innerWidth > 992) {
      document.getElementsByTagName('nav')[0].classList.remove('fixed-top');
      document.getElementsByClassName('main_wrapper')[0].style.marginTop = 0;
    }
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    document.getElementsByTagName('nav')[0].classList.add('fixed-top');
    document.getElementsByClassName('main_wrapper')[0].style.marginTop = '64px';
    this.setState({
      showModal: false,
    });
  };

  animateMosaic = InView => {
    const tl1 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tl1, '.animate-up');
      globalAnimation.fadeLeft(tl1, '.animate-left');
      globalAnimation.fadeRight(tl1, '.animate-right');
    }
  };

  animateBottomSection = InView => {
    const tl1 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tl1, '.bottom-desc');
    }
  };

  animateCards = InView => {
    const tlCards = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      Probono.applyCards(tlCards, '.animate-cardx');
    }
  };

  carddescription = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateApplyDescription(
        tl,
        '.svg-line__line_lightblue_second',
        '.svg_circle_lightblue_second',
        '.title-up',
        '#description-up'
      );
    }
  };

  render() {
    const { showModal, id } = this.state;
    const {
      data: {
        markdownRemark: {
          html,
          frontmatter: { title, sectionOne, sectionTwo, colOne, colTwo, colThree, sectionThree },
        },
      },
    } = this.props;

    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Apply Now" />
        <Helmet>
          <link
            rel="preload"
            as="style"
            onLoad="this.rel = 'stylesheet'"
            type="text/css"
            href="https://reports.hrmdirect.com/employment/default/sm/settings/dynamic-embed/dynamic-iframe-embed-css.php"
          />
          <script
            type="text/javascript"
            async
            defer
            rel="preload"
            as="script"
            src="https://reports.hrmdirect.com/employment/default/sm/settings/dynamic-embed/dynamic-iframe-embed-js.php"
          />
          <noscript>
            {`<link
              rel="stylesheet"
              href="https://reports.hrmdirect.com/employment/default/sm/settings/dynamic-embed/dynamic-iframe-embed-css.php"
            />`}
          </noscript>
        </Helmet>
        <Container fluid className="main_wrapper section-one">
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="wrapper">
                <div className="page_heading_section text-center">
                  <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                  <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                </div>
              </div>
            </Col>
            <Col xs={11} lg={4}>
              <div className="bg-appcolor mt-lg-5 blue_bg d-none d-lg-block">
                <Img
                  fluid={sectionOne.image.childImageSharp.fluid}
                  className="h-100 w-100 apply-image"
                />
              </div>
              <div className="bg-appcolor mt-lg-5 d-lg-none">
                sds
                <Img fluid={sectionOne.image.childImageSharp.fluid} className="h-100 w-100 " />
              </div>
            </Col>
            <Col xs={12} lg={8}>
              <div className="wrapper pt-0 mt-2 mt-lg-5">
                <h5 className="text-appcolor mb-0" id="title">
                  {sectionOne.title}
                </h5>
                <LightBluekey width="100px" />
                <div id="description">
                  {sectionOne.description1.map(description => (
                    <p>{description}</p>
                  ))}
                </div>
              </div>
            </Col>
          </Row>

          <Observer onChange={this.animateCards} triggerOnce>
            <Row className="wrapper py-lg-5 justify-content-center">
              {sectionOne.universities.map((university, index) => (
                <Col xs={11} lg={3} className="text-center my-3 animate-cardx">
                  <Button
                    onClick={() => {
                      this.handleShowModal();
                      this.setState({
                        id: index,
                      });
                    }}
                    className="w-100 h-100 border-0 py-3 px-2 bg-white shadow justify-content-center"
                  >
                    <div className="px-5 w-100">
                      <Img
                        style={{ maxHeight: '90px', maxWidth: '90px' }}
                        fluid={university.logo.childImageSharp.fluid}
                        className="mx-auto"
                      />
                    </div>

                    <p
                      className="text-semibold text-appcolor mt-1 mb-0"
                      style={{ lineHeight: 'normal' }}
                    >
                      {university.name}
                    </p>
                  </Button>
                </Col>
              ))}

              <Modal
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="custom_modal"
                show={showModal}
                onHide={this.handleClose}
              >
                <Modal.Header closeButton className="border-0 pb-0" />
                <Modal.Body className="mx-lg-4 mx-2 py-0 px-lg-5">
                  {sectionOne.universities.map((university, index) => (
                    <div>
                      {index === id ? (
                        <div>
                          {console.log(university)}
                          <Row>
                            <Col xs={12}>
                              <h4 className="text-appcolor text-center text-lg-left">
                                {university.name}
                              </h4>
                            </Col>
                          </Row>
                          <Row className="my-4">
                            <Col xs={12} lg={10}>
                              <div>
                                {university.title1 !== null ? (
                                  <h5 className="text-appcolor text-appcolor text-center text-lg-left mb-3">
                                    {university.title1}
                                  </h5>
                                ) : null}
                                {university.dates !== null ? (
                                  <div className="circle-light-bullets-small">
                                    <ul style={{ paddingLeft: '23px' }}>
                                      {university.dates.map(date => (
                                        <li className="pl-2">{date}</li>
                                      ))}
                                    </ul>
                                  </div>
                                ) : null}
                                {university.note !== null ? <p>{university.note}</p> : null}
                                {university.title2 !== null ? (
                                  <h5 className="text-appcolor">{university.title2}</h5>
                                ) : null}
                                {university.date2 !== null ? <p>{university.date2}</p> : null}
                                {university.title3 !== null ? (
                                  <h5 className="text-appcolor">{university.title3}</h5>
                                ) : null}
                                {university.date3 !== null ? (
                                  <div className="circle-light-bullets-small">
                                    <ul style={{ paddingLeft: '23px' }}>
                                      {university.date3.map(date => (
                                        <li className="pl-2">{date}</li>
                                      ))}
                                    </ul>
                                  </div>
                                ) : null}
                              </div>

                              <a
                                href={university.link}
                                className="apply_now_btn text-decoration-none py-2 px-5"
                                // eslint-disable-next-line react/jsx-no-target-blank
                                target="_blank"
                              >
                                Apply Now
                              </a>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </Modal.Body>
              </Modal>
            </Row>
          </Observer>

          <Observer onChange={this.carddescription} triggerOnce>
            <Row className="wrapper py-0">
              <Col xs={12}>
                <h5 className="text-appcolor mb-0 title-up">{sectionTwo.title}</h5>
                <LightBluekeySecond width="100px" />
                <p id="description-up">{sectionTwo.description}</p>
              </Col>
              <Col>
                <div className="iframe-wrapper py-lg-0">
                  <div
                    id="iframeHeightDiv"
                    name="HRM Direct Career Site iFrame Container"
                    align="center"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </div>
              </Col>
            </Row>
          </Observer>
          <Observer onChange={this.animateMosaic} triggerOnce>
            <Row className="bg-appcolor justify-content-center p-5">
              <Col xs={12} lg={3} className="px-2 d-flex flex-column">
                <div className="brick animate-left mb-3">
                  <Img fluid={colOne.image.childImageSharp.fluid} />
                </div>
                <div className="bg-white align-self-stretch animate-left h-100">
                  <Card className="apply-now-card">
                    <Card.Body>
                      <Card.Title className="text-bold text-appcolor text-center">
                        {colOne.title}
                      </Card.Title>
                      <Card.Text className="text-small" style={{ color: '#363636' }} />
                      <div className="d-flex justify-content-center">
                        <div className="py-3">
                          <Link
                            to="/our-opportunity-career-path/"
                            className="bordered-btn black text-center learn_more"
                          >
                            Discover more{' '}
                            <ReadMore className="arrow-translate mb-0" height="10px" width="40px" />
                          </Link>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col xs={12} lg={3} className="px-2">
                <div className="mb-3 d-none d-lg-block animate-up">
                  <Card className="apply-now-card">
                    <Card.Body>
                      <Card.Title className="text-bold text-appcolor text-center">
                        {colTwo.title}
                      </Card.Title>
                      <Card.Text className="text-small" style={{ color: '#363636' }} />
                      <div className="d-flex justify-content-center">
                        <div className="py-3">
                          <Link
                            to="/what-is-the-bluePrint-culture-like/"
                            className="learn_more bordered-btn black text-center"
                          >
                            Discover more{' '}
                            <ReadMore className="arrow-translate mb-0" height="10px" width="40px" />
                          </Link>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="animate-up my-3 my-lg-0">
                  <Img fluid={colTwo.image.childImageSharp.fluid} />
                </div>
                <div className="mb-3 d-lg-none">
                  <Card className="apply-now-card animate-up">
                    <Card.Body>
                      <Card.Title className="text-bold text-appcolor text-center">
                        {colTwo.title}
                      </Card.Title>
                      <Card.Text className="text-small" style={{ color: '#363636' }} />
                      <div className="d-flex justify-content-center">
                        <div className="py-3">
                          <Link
                            to="/what-is-the-bluePrint-culture-like/"
                            className="learn_more bordered-btn black text-center"
                          >
                            Discover more{' '}
                            <ReadMore className="arrow-translate mb-0" height="10px" width="40px" />
                          </Link>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col xs={12} lg={3} className="px-2 d-flex flex-column">
                <div className="animate-right mb-3">
                  <Img fluid={colThree.image.childImageSharp.fluid} />
                </div>
                <div className="bg-white animate-right align-self-stretch h-100">
                  <Card className="apply-now-card">
                    <Card.Body>
                      <Card.Title className="text-bold text-appcolor text-center">
                        {colThree.title}
                      </Card.Title>
                      <Card.Text className="text-small" style={{ color: '#363636' }} />
                      <div className="d-flex justify-content-center">
                        <div className="py-3">
                          <Link
                            to="/our-opportunity-develop-and-grow/"
                            className="learn_more bordered-btn black text-center"
                          >
                            Discover more{' '}
                            <ReadMore className="arrow-translate mb-0" height="10px" width="40px" />
                          </Link>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Observer>
          <Observer onChange={this.animateBottomSection} triggerOnce>
            <Row className="bg-appcolor justify-content-center">
              <Col xs={12} lg={9} className="text-center">
                <p
                  className="text-small text-white px-5 pb-5 mb-0 bottom-desc"
                  style={{ lineHeight: 'normal' }}
                >
                  {sectionThree.description}
                </p>
              </Col>
            </Row>
          </Observer>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "apply-now" } }) {
      frontmatter {
        title
        sectionOne {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          description1
          universities {
            name
            logo {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title1
            dates
            title2
            date2
            title3
            date3
            note
            link
          }
        }
        sectionTwo {
          title
          description
        }
        colOne {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        colTwo {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        colThree {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sectionThree {
          description
        }
      }
      html
    }
  }
`;
