import { Power1, Power2 } from 'gsap';

export function applyCards(tl, wrapper) {
  return tl.staggerFrom(
    wrapper,
    0.4,
    {
      scale: 0,
      ease: Power1.easeOut,
    },
    0.2
  );
}

export function animateImageBottomRight(
  tl,
  svgLine,
  svgCircle,
  image,
  image2,
  container,
  title,
  description,
  button
) {
  const o = 0.5;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 15,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      button,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      container,
      1.3,
      {
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFromTo(
      image,
      1.3,
      {
        x: '0px',
        y: '-56px',
        opacity: 0,
      },
      {
        x: '70px',
        y: '56px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      image2,
      1.3,
      {
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateImageFade(tl, image, title, description, icons) {
  const o = 0.5;
  return tl
    .staggerFromTo(
      image,
      o,
      {
        opacity: 0,
        ease: Power2.easeOut,
      },
      {
        opacity: 1,
      }
    )
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 5,
        ease: Power2.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power2.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      icons,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power2.easeOut,
      },
      0,
      o
    );
}

export function animateCaseStudiesCards(tl, element) {
  const o = 1.3;
  return tl.staggerFrom(element, o, {
    opacity: 0,
    ease: Power2.easeOut,
  });
}

export function animateProjectCommittee(tl, box, image, title, description, icon) {
  const o = 0.8;
  return tl
    .staggerFromTo(
      box,
      o,
      {
        opacity: 0,

        ease: Power2.easeOut,
      },
      {
        opacity: 1,
      }
    )
    .staggerFromTo(
      image,
      o,
      {
        opacity: 0,
        y: '125px',
        ease: Power2.easeOut,
      },
      {
        opacity: 1,
        y: '0px',
      }
    )
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 5,
        ease: Power2.easeOut,
      },
      0,
      1.3
    )
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 10,
        ease: Power2.easeOut,
      },
      0,
      1.3
    )
    .staggerFrom(icon, 0.5, { scale: 0.5, autoAlpha: 0 }, 0, 1.3);
}

export function animateCards(tl, element1, element2) {
  return tl
    .staggerFrom(
      element1,
      0.5,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.5
    )
    .staggerFrom(
      element2,
      0.5,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.5
    );
}
